<template>
  <div id="CONTENT">
    <tagline :sitename="sitename" :title="title"></tagline>
    <navidiv></navidiv>
    <!-- H1 -->
    <div class="h1-binder">
      <h1 class="b3">{{ title }}</h1>
      <!-- 検索フォーム -->
      <form method="get" action="/annotations/search" id="SEARCH-FORM" name="Search">
        <input type="text" v-model="keywords" value="" name="keywords" class="keyword" /><input type="image" src="/img/btn_search.gif" value="" alt="検索" name="" class="submit" />
      </form>
      <!-- /検索フォーム -->
    </div>
    <!-- /H1 -->
    <!-- パンくず -->
    <div id="TOPIC-PATH"><a href="http://ebiki.jp/">トップページ</a> &gt; <a href="/">絵引データベース</a> &gt; {{ title }}</div>
    <!-- /パンくず -->
    <!-- コンテンツ -->
    <div id="CONTENT-BODY">
      <!-- H2 -->
      <div class="h2-binder">
        <h2>検索結果</h2>
      </div>
      <!-- /H2 -->
      <!-- 表示制御 -->
      <div class="controller">
        <!-- ページ送り -->
        <div class="paginate"><paginate-links v-if="total" for="annotations" :show-step-links="true" :async="true"></paginate-links></div>
        <!-- /ページ送り -->
        <!-- 表示件数 -->
        <ul class="limit">
          <li :class="[this.limit == 10 ? 'selected' : '']"><router-link :to="{ path: '/annotations/search/', query: {keywords: this.keywords, limit: 10}}">表示数 10</router-link></li>
          <li :class="[this.limit == 20 ? 'selected' : '']"><router-link :to="{ path: '/annotations/search/', query: {keywords: this.keywords, limit: 20}}">表示数 20</router-link></li>
          <li :class="[this.limit == 50 ? 'selected' : '']"><router-link :to="{ path: '/annotations/search/', query: {keywords: this.keywords, limit: 50}}">表示数 50</router-link></li>
          <li :class="[this.limit == 100 ? 'selected' : '']"><router-link :to="{ path: '/annotations/search/', query: {keywords: this.keywords, limit: 100}}">表示数 100</router-link></li>
        </ul>
        <!-- /表示件数 -->
        <!-- 総数 -->
        <p class="total">全{{ total }}件</p>
        <!-- /総数 -->
      </div>
      <!-- /表示制御 -->
			<!-- 一覧 -->
      <paginate v-if="total" name="annotations" :list="ebikis" :per="limit" :tag="'table'" :class="'base-skin'">
        <tr>
          <th scope="col" class="first image">画像</th>
          <th scope="col"><span @click="sortBy('name_kana')" :class="thClass('name_kana')">図像・事物</span></th>
          <th scope="col"><span @click="sortBy('work_title_kana')" :class="thClass('work_title_kana')">作品</span></th>
        </tr>
        <template v-for="ebiki in paginated('annotations')">
          <tr :key="ebiki.id">
            <td><router-link :to="{ path: '/annotations/view/' + ebiki.id}"><img :src="ebiki.image" :alt="ebiki.name" :title="ebiki.name" width="70" /></router-link></td>
            <td><router-link :to="{ path: '/annotations/view/' + ebiki.id}">{{ ebiki.name }}</router-link></td>
            <td><router-link :to="{ path: '/works/view/' + ebiki.work_id}">{{ ebiki.work_title }}</router-link></td>
          </tr>
        </template>
      </paginate>
      <template v-else>
        <table class="base-skin">
          <tr>
            <th scope="col" class="first image">画像</th>
            <th scope="col"><span @click="sortBy('name_kana')" :class="thClass('name_kana')">図像・事物</span></th>
            <th scope="col"><span @click="sortBy('work_title_kana')" :class="thClass('work_title_kana')">作品</span></th>
          </tr>
        </table>
      </template>
			<!-- 一覧 -->
      <!-- 表示制御 -->
      <div class="controller">
        <!-- ページ送り -->
        <div class="paginate"><paginate-links v-if="total" for="annotations" :show-step-links="true" :async="true"></paginate-links></div>
        <!-- /ページ送り -->
        <!-- 表示件数 -->
        <ul class="limit">
          <li :class="[this.limit == 10 ? 'selected' : '']"><router-link :to="{ path: '/annotations/search/', query: {keywords: this.keywords, limit: 10}}">表示数 10</router-link></li>
          <li :class="[this.limit == 20 ? 'selected' : '']"><router-link :to="{ path: '/annotations/search/', query: {keywords: this.keywords, limit: 20}}">表示数 20</router-link></li>
          <li :class="[this.limit == 50 ? 'selected' : '']"><router-link :to="{ path: '/annotations/search/', query: {keywords: this.keywords, limit: 50}}">表示数 50</router-link></li>
          <li :class="[this.limit == 100 ? 'selected' : '']"><router-link :to="{ path: '/annotations/search/', query: {keywords: this.keywords, limit: 100}}">表示数 100</router-link></li>
        </ul>
        <!-- /表示件数 -->
        <!-- 総数 -->
        <p class="total">全{{ total }}件</p>
        <!-- /総数 -->
      </div>
      <!-- /表示制御 -->
    </div>
    <!-- /コンテンツ -->
    <footerdiv></footerdiv>
  </div>
</template>

<script>
/* eslint-disable no-console */
import { Backend } from '@/ajax/Backend'
const iiifLayer = Backend.get('layer')
const iiifAnnotationList = Backend.get('list')

// @ is an alias to /src
import tagline from '@/components/tagline.vue'
import navidiv from '@/components/navi.vue'
import footerdiv from '@/components/footer.vue'

import $ from 'jquery'
import ebikiMixin from '@/mixins/ebikiMixin.js'

export default {
  name: 'annotation_search',
  mixins: [ ebikiMixin ],
  components: {
    tagline,
    navidiv,
    footerdiv
  },
  props: {
    sitename: { type: String, required: true },
    orgname: { type: String, required: true },
    manifest:  { type: Object, required: true },
    manifest_id:  { type: Number, required: true },
    title:  { type: String, required: true },
    meta_description:  { type: String, required: true },
    meta_keywords:  { type: String, required: true },
  },
  data: function () {
    return {
      keywords: '',
      limit: 20,
      paginate: ['annotations'],
      annotations: [],
    };
  },
  computed: {
    ebikis: function() {
      let data = []
      if (this.annotations) {
        this.annotations.forEach((_, i) => {
          const canvas_id = this.annotations[i].on.match(/canvas\/(\d+)/)[1]
          const name = this.annotations[i].label
          const name_kana = this.findMetadata(this.annotations[i].metadata, "name_kana")
          const description = this.annotations[i].description
          if (this.keywords) {
            // 検索キーワードによる絞り込み
            const re = new RegExp(this.keywords)
            const val = (name ? name : '')+(name_kana ? name_kana : '')+(description ? description : '')
            if (val.match(re)) {
              data.push({
                id: this.annotations[i]['@id'].match(/\d+$/)[0],
                image: this.annotations[i].thumbnail['@id'],
                name: name,
                name_kana: name_kana,
                work_id: canvas_id,
                work_title: this.getCanvasName(canvas_id),
                work_title_kana: this.getCanvasNameKana(canvas_id),
              })
            }
          }
        });
        if (data) {
          // 並び替え
          if (this.sort.key) {
            data.sort((a, b) => {
              a = a[this.sort.key]
              b = b[this.sort.key]
              return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.direction === 'desc' ? -1 : 1)
            });
          } else {
            // デフォルトはアノテーションIDの数値順
            data.sort((a, b) => {
              a = a['id']
              b = b['id']
              return (this.sort.direction === 'desc' ? b-a : a-b)
            });
          }
        }
      }
      return data
    },
    total: function() {
      if (this.ebikis) {
        return this.ebikis.length
      }
      return null
    }
  },
  watch: {
    $route: {
      handler: function() {
        if (this.$route.query.limit) {
          this.limit = Number(this.$route.query.limit)
        }
        if (this.$route.query.keywords) {
          this.keywords = this.$route.query.keywords
        }
      },
      immediate: true,
    },
    limit: function() {
      $('.select-collapsed p').text('表示数 '+this.limit)
    },
    manifest: function() {
      this.createAnnotations()
    }
  },
  mounted: function() {
    this.createAnnotations()
  },
  methods: {
    // manifest apiから指定したIDのカンバス名の取得
    getCanvasName: function(canvas_id) {
      return this.manifest.sequences[0].canvases.find(canvas => canvas['@id'].match(/canvas\/(\d+)/)[1] == canvas_id).label
    },
    // manifest apiから指定したIDのカンバス名かなの取得
    getCanvasNameKana: function(canvas_id) {
      return this.findMetadata(this.manifest.sequences[0].canvases.find(canvas => canvas['@id'].match(/canvas\/(\d+)/)[1] == canvas_id).metadata, "name_kana")
    },
    // レイヤーAPIの読み込み
    getLayer: function(url) {
      return iiifLayer.getByURL(url)
        .then(response => {return response.data})
        .catch(err => console.log(err))
    },
    // アノテーションリストAPIの読み込み
    getAnnotationList: function(url) {
      return iiifAnnotationList.getByURL(url)
        .then(response => {return response.data})
        .catch(err => console.log(err))
    },
    // 検索対象となるアノテーション一覧の作成
    createAnnotations: function() {
      if (this.manifest.structures) {
        this.manifest.structures[0].members.forEach(async (_, i) => {
          const layer = await this.getLayer(this.manifest.structures[0].members[i].contentLayer)
          if (layer.otherContent) {
            layer.otherContent.forEach(async (_, j) => {
              // アノテーションリストAPIの読み込み
              const annotationlist = await this.getAnnotationList(layer.otherContent[j])
              if (annotationlist.resources) {
                annotationlist.resources.forEach(async (_, k) => {
                  this.annotations.push(annotationlist.resources[k])
                });
              }
            });
          }
        });
      }
    }
  },
  head: {
    title: function () {
      return {
        inner: this.sitename + ' ' + this.title + ' - ' + this.orgname,
      }
    },
    meta: function () {
      return [
        { name: 'description', content: this.meta_description },
        { name: 'keywords', content: this.meta_keywords },
        { property: 'og:title', content: this.sitename + ' ' + this.title + ' - ' + this.orgname },
        { property: 'og:description', content: this.meta_description },
      ]
    }
  }
}
</script>
